import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

body {
  font-size: 1.6rem;
  height: 100%;
  font-family: ${props => props.theme.fonts.primary};
  position: relative;
  overflow-x: hidden;
}

a,
a:hover {
  color: black;
  text-decoration: none;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 30em) {
  .container {
    max-width: 28em;
  }
}

@media (min-width: 37.5em) {
  .container {
    max-width: 35em;
  }
}

@media (min-width: 46.5em) {
  .container {
    max-width: 44em;
  }
}

@media (min-width: 59em) {
  .container {
    max-width: 57em;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 71.25em;
  }
}

@media (min-width: 87.5em) {
  .container {
    max-width: 84em;
  }
}

@media (min-width: 112.5em) {
  .container {
    max-width: 87.5em;
  }
}

.grecaptcha-badge {
  z-index: 100;
}
`;

export default GlobalStyle;
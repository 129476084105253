import styled from 'styled-components'

export const FooterContainer = styled.footer`
  width: 100%;
  background-color: #131415;
  position: relative;
  z-index: 2;
  
  .container {
    position: relative;
    height: 100%;
  }
`;

export const FooterMenu = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterMenuItem = styled.div`
  a {
    display: block;
    color: #A7A7A7;
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.6rem;
    padding: 3.7rem 0;
    
    &:hover { color: #A7A7A7; }
  }
  
  &:not(:last-child) {
    margin-right: 2.6rem;
  }
`;

export const BtnToTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252525;
  box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.5);
  width: 4.8rem;
  height: 4.8rem;
  position: absolute;
  right: 0;
  z-index: 10;
  transform: translateY(-50%);
  
  &:hover { cursor: pointer; }
`;
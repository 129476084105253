import React from "react"
import { Link } from "gatsby";
import { FooterContainer, FooterMenu, FooterMenuItem, BtnToTop } from "./footer.styles";

const Footer = () => {
  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  return (
      <FooterContainer>
        <div className="container">
          <BtnToTop onClick={scrollToTop}>
            <img src="/images/icons/arrow_top.svg" alt=""/>
          </BtnToTop>

          <FooterMenu>
            <FooterMenuItem>
              <Link to={'/kontakt'}>Kontakt</Link>
            </FooterMenuItem>
            <FooterMenuItem>
              <Link to={'/'}>Polityka prywatności</Link>
            </FooterMenuItem>
            <FooterMenuItem>
              <Link to={'/kontakt'}>FAQ</Link>
            </FooterMenuItem>
          </FooterMenu>
        </div>

      </FooterContainer>
  )
}

export default Footer
import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
import { NavContainer, Logo, NavList, NavItem, NavSubmenu, NavSocial, NavBtn, NavIcon } from "./navigation.styles";

const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openDodge, setOpenDodge] = useState(false)
  const [openRam, setOpenRam] = useState(false)

  useEffect(() => {
    document.addEventListener('scroll', _ => scrollFunction())
  })

  function scrollFunction() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      // document.getElementById("nav").style.backgroundColor = "rgba(0, 0, 0, 0.6)";
      document.getElementById("nav").classList.add("shrink");
    } else {
      // document.getElementById("nav").style.backgroundColor = "transparent";
      document.getElementById("nav").classList.remove("shrink");
    }
  }

  return (
      <NavContainer id={`nav`}>
        <div className="container">
          <Link to={'/'}>
            <Logo className={openMenu ? `logo open` : `logo`}>
              <img src="/images/logo.svg" alt=""/>
            </Logo>
          </Link>

          <NavList className={openMenu ? `open` : ``}>
            <NavItem>
              <Link to={'/dodge'} activeClassName="active">Dodge</Link>
              <div className="line"/>
              <div className="icon-down" onClick={() => setOpenDodge(!openDodge)}>
                <img src="/images/icons/chevron-down-outline.svg" alt="" />
              </div>

              <NavSubmenu className={openDodge ? `submenu open` : `submenu`}>
                <Link to={'/dodge/challenger'}>dodge challenger</Link>
                <Link to={'/dodge/charger'}>dodge charger</Link>
                <Link to={'/dodge/durango'}>dodge durango</Link>
              </NavSubmenu>
            </NavItem>
            <NavItem>
              <Link to={'/ram'} activeClassName="active">RAM</Link>
              <div className="line"/>
              <div className="icon-down" onClick={() => setOpenRam(!openRam)}>
                <img src="/images/icons/chevron-down-outline.svg" alt="" />
              </div>

              <NavSubmenu className={openRam ? `submenu open` : `submenu`}>
                <Link to={'/ram/bighorn'}>ram bighorn</Link>
                <Link to={'/ram/rebel'}>ram rebel</Link>
                <Link to={'/ram/laramie'}>ram laramie</Link>
                <Link to={'/ram/longhorn'}>ram longhorn</Link>
                <Link to={'/ram/limited'}>ram limited</Link>
              </NavSubmenu>
            </NavItem>
            <NavItem>
              <Link to={'#'}>Oferta</Link>
              <div className="line"/>
            </NavItem>
            <NavItem>
              <Link to={'/salon'}>Salon</Link>
              <div className="line"/>
            </NavItem>
            <NavItem>
              <Link to={'/serwis'} activeClassName="active">Serwis</Link>
              <div className="line"/>
            </NavItem>
            <NavItem>
              <Link to={'/kontakt'} activeClassName="active">Kontakt</Link>
              <div className="line"/>
            </NavItem>
          </NavList>

          <NavSocial className={`nav-social`}>
            <a href='https://www.facebook.com/Dodge.RAM.Rzeszow' target="_blank" rel="nofollow noreferrer" >
              <img src="/images/icons/icon-fb.svg" alt=""/>
            </a>
            <a href='https://www.instagram.com/dodge.ram.rzeszow/' target="_blank" rel="nofollow noreferrer">
              <img src="/images/icons/icon-instagram.svg" alt=""/>
            </a>
            <a href='https://www.goc-harley-davidson.pl/' target="_blank" rel="nofollow noreferrer">
              <img src="/images/icons/GOC_site.svg" alt=""/>
            </a>
            <NavBtn className={openMenu ? `open` : ``} onClick={() => setOpenMenu(!openMenu)}>
              <NavIcon/>
            </NavBtn>
          </NavSocial>

        </div>

      </NavContainer>
  )
}

export default Nav
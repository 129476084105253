export const sizes = {
  xxs: 25, // 400px
  xs: 30, // 480px
  phone: 37.5, // 600px
  tabPort: 59, //800px
  tabLand: 75, // 1200px
  bigDesktop: 87.5, //1400px
  largeDesktop: 112.5, //1800px
}

export const breakpoints = key => {
 if (key ===  'bigDesktop' || key === 'largeDesktop') {
   return style => `@media (min-width: ${sizes[key]}em) { ${style} }`
 } else {
   return style => `@media (max-width: ${sizes[key]}em) { ${style} }`
 }
}
import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const NavContainer = styled.nav`
  width: 100%;
  height: 16rem;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: height .2s ease-in;
  
  &.shrink {
    height: 8.2rem;
    
    .nav-social {
      opacity: 0;
      visibility: hidden;
      
      ${breakpoints('tabLand') `
        opacity: 1;
        visibility: visible;
      `} 
    }
    
    .logo{
      margin-right: 4rem;
      
      ${breakpoints('tabLand') `
        margin-right: 0;
      `}
      
      img {
        width: 23rem;
        
        ${breakpoints('bigDesktop') `
          width: 28rem
        `} 
        
        ${breakpoints('tabLand') `
          width: 18rem
        `} 
        
        ${breakpoints('xs') `
          width: 14rem
        `} 
      }
    }
    
  }
  
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  
  ${breakpoints('tabLand') `
    height: 8.2rem;
  `} 
`;

export const Logo = styled.div`
  position: relative;
  z-index: 11;
  transition: margin-right .2s;
  
  img {
      width: 26rem;
      transition: width .2s;
      
      ${breakpoints('bigDesktop') `
        width: 32rem
      `} 
      
      ${breakpoints('tabLand') `
        width: 18rem
      `} 
      
      ${breakpoints('xs') `
        width: 14rem
      `} 
    }
    
    //&.open {
    //  position: fixed;
    //  z-index: 11;
    //}
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  width: 50%;
  height: 100%;
  
  ${breakpoints('tabLand') `
    display: none;
  `} 
  
  &.open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    //justify-content: center;
    
    ${breakpoints('tabLand') `
      padding-top: 20%;
      justify-content: flex-start;
    `} 
    
    ${breakpoints('phone') `
      padding-top: 30%;
    `} 
  }
`;

export const NavItem = styled.li`
  //width: 24rem;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  
  ${breakpoints('tabLand') `
    flex-direction: column; 
    height: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  `} 
    
  @media (min-width: 75em) {
    &:hover > .submenu {
      max-height: 500rem;
      opacity: 1;
      visibility: visible;
      padding: 1.8rem 2rem;
    }
  }
  
  .submenu.open {
    max-height: 500rem;
    opacity: 1;
    visibility: visible;
    padding: 1.8rem 2rem;
  }
  
  a {
    color: ${props => props.active ? props.theme.colors.primary : 'white'};
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-right: 2rem;
    transition: color .3s;
    
    ${breakpoints('tabLand') `
      margin-right: 0;
    `} 
    
    &:hover, &.active { 
      color: ${props => props.theme.colors.primary};
    };
    
    &:hover ~ .line,
    &.active ~ .line {
      background-color: ${props => props.theme.colors.primary};
    }
  }
  
  .line {
    width: 4.4rem;
    height: 1px;
    background-color: ${props => props.active ? props.theme.colors.primary : 'white'};
    transition: background-color .3s;
    
    ${breakpoints('tabLand') `
      display: none;
    `} 
  }
  
  .icon-down {
    display: none;
    width: 1.4rem;
    position: absolute;
    top: 5px;
    right: 5rem;
    
    ${breakpoints('tabLand') `
      display: block;
    `}   
  }
`;

export const NavSubmenu = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0,0,0,0.86);
  border-top: 4px solid ${props => props.theme.colors.primary};
  position: absolute;
  top: 100%;
  width: 20rem;
  padding: 1.8rem 2rem;
  z-index: 1000;
  transition: opacity .3s;
  max-height: 0;
  
  ${breakpoints('tabLand') `
      position: relative;
      top: 0;
      border-top: none;
      text-align: center;
      padding: 0;
  `} 
  
  a {
    font-weight: 200;
    margin-bottom: 8px;
  }
`;

export const NavSocial = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
  visibility: visible;
  transition: opacity .3s;
  
  ${breakpoints('tabLand') `
    flex-direction: row;
    height: 2.4rem;
  `} 
  
  a:not(:last-of-type) {
    width: 2.4rem;
    height: 2.4rem;
    margin-bottom: 1.6rem;
    
    ${breakpoints('tabLand') `
      margin-right: 1.6rem;
      margin-bottom: 0;
    `} 
    
    ${breakpoints('xs') `
      margin-right: 1.2rem;
    `} 
  }
`;

export const NavBtn = styled.div`
    width: 2.4rem;
    height: 2.4rem;
    display: none; 
    align-items: center;
    justify-content: center;
    margin-left: 1.6rem;
    position: relative;
    z-index: 11;
    
    //&.open {
    //  position: fixed;
    //  right: 1.5rem;
    //  z-index: 11;
    //}
    
    ${breakpoints('tabLand') `
      display: flex; 
    `} 
    
    ${breakpoints('xs') `
      margin-left: 1.2rem;
    `} 
`;

export const NavIcon = styled.div`
  position: relative;
  
  &,
  &:after,
  &:before {
    width: 2.4rem;
    height: 1px;
    background-color: #e4e4e4;
  }
  
  &:after,
  &:before {
    content: '';
    position: absolute;
  }
  
  &:after { top: -1rem;}
  &:before { top: 1rem;}
`;
import React from "react"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Nav from "./navigation/navigation";
import Footer from "./footer/footer";
import GlobalStyle from "../styles/global.styles";

const Layout = ({title, description, image, children, meta}) => {
  const { site } = useStaticQuery(
      graphql`
          query {
              site {
                  siteMetadata {
                      title
                  }
              }
          }
      `
  )

  const metaDescription = description || site.siteMetadata.description
  // const imageOg =  `${site.siteMetadata.siteUrl}${image || site.siteMetadata.image}`

  return (
      <div>
        <Helmet
            htmlAttributes={{
              lang: 'pl'
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              }
            ]}/>
        <GlobalStyle/>
        <Nav />
        {children}
        <Footer/>
      </div>
  )
}

export default Layout;